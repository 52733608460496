import { useState } from 'react'
import WebAutomation from './components/WebAutomation'
import WorkflowExecutions from './components/WorkflowExecutions'
import DocumentHead from './components/DocumentHead'
import { Play, List } from 'lucide-react'

function App() {
  const [currentView, setCurrentView] = useState('main')

  return (
    <>
      <DocumentHead />
      <div className="max-w-4xl mx-auto p-4">
        <div className="flex items-center gap-4 mb-6">
          <button
            className={`flex items-center gap-2 px-4 py-2 rounded-md ${
              currentView === 'main' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
            }`}
            onClick={() => setCurrentView('main')}
          >
            <Play size={16} /> Workflow
          </button>
          <button
            className={`flex items-center gap-2 px-4 py-2 rounded-md ${
              currentView === 'executions' ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
            }`}
            onClick={() => setCurrentView('executions')}
          >
            <List size={16} /> Executions
          </button>
        </div>

        {currentView === 'main' ? <WebAutomation /> : <WorkflowExecutions />}
      </div>
    </>
  )
}

export default App
