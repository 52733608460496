import Pusher from 'pusher-js';

// Enable Pusher logging
Pusher.logToConsole = true;

const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
  cluster: import.meta.env.VITE_PUSHER_CLUSTER
});

// Log connection state changes
pusher.connection.bind('state_change', states => {
  console.log('Pusher state changed:', states);
});

export default pusher; 